import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs";

let cropper;

const frameSizes = [
  { width: 16, height: 20 },
  { width: 18, height: 24 },
  { width: 22, height: 28 },
  { width: 20, height: 30 },
  { width: 24, height: 36 },
  { width: 27, height: 40 }
];

export default class extends Controller {
  static targets = [
      "predefinedSize",
      "printWidth",
      "printHeight",
      "paper",
      "framing",
      "subtotalPrice",
      "regularPrice",
      "productType",
      "initialRatio",
      "sourceImage",
      "croppedX",
      "croppedY",
      "croppedW",
      "croppedH",
      "croppedFile",
      "testImage",
      "laminationCheckbox",
      "tag",
      "previewImage",
      "mountMedia",
      "productId"
  ]

  connect() {
    this.createCropper();
    this.fetchPrice();
    this.updateSelectableFrames()
  }

  createCropper() {
    const image = this.sourceImageTarget;
    const croppedX = this.croppedXTarget;
    const croppedY = this.croppedYTarget;
    const croppedW = this.croppedWTarget;
    const croppedH = this.croppedHTarget;
    const croppedFile = this.croppedFileTarget;

    cropper = new Cropper(image, {
      aspectRatio: this.initialRatioTarget.value,
      dragMode: 'move',
      viewMode: 2,
      autoCropArea: 1,
      restore: false,
      modal: true,
      guides: true,
      center: false,
      background: false,
      highlight: true,
      cropBoxMovable: true,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      checkCrossOrigin: false,
      crop(event) {
        croppedX.value = event.detail.x;
        croppedY.value = event.detail.y;
        croppedW.value = event.detail.width;
        croppedH.value = event.detail.height;

        // let croppedData = cropper.getCroppedCanvas({fillColor: '#fff'})
        //
        // croppedFile.value = croppedData.toBlob;

        let croppedData = cropper.getCroppedCanvas({
          fillColor: '#fff',
          imageSmoothingEnabled: false,
          imageSmoothingQuality: 'high'
        }).toDataURL('image/jpeg');

        // replace the mime-type
        croppedData = croppedData.replace(/^data:image\/(jpeg);base64,/, "");

        croppedFile.value = croppedData;

        // let file = new File([croppedData], 'cropped_file.jpg',{type:"image/jpeg", lastModified:new Date().getTime()}, 'utf-8');
        // let container = new DataTransfer();
        // container.items.add(file);
        // croppedFile.files = container.files;

      },
    });

  }

  downloadProof() {
    const testImage = this.testImageTarget;
    let base64data = cropper.getCroppedCanvas({fillColor: '#fff', imageSmoothingEnabled:false}).toDataURL('image/jpeg')
    testImage.href = base64data //Image Base64 Goes here
    testImage.download = "proof.jpg" //File name Here
    testImage.click() //Downloaded file
  }

  setPredefinedSize() {
    // Get the selected value
    let size = this.predefinedSizeTarget.value

    // If nothing was selected, empty the inputs and return
    if (!size) {
      this.printWidthTarget.value = ''
      this.printHeightTarget.value = ''
      return
    }

    // Split the size into width and height
    let [width, height] = size.split('x')

    // Set the width and height inputs
    this.printWidthTarget.value = width
    this.printHeightTarget.value = height

    this.changeCropperRatio()

    this.updateSelectableFrames()

    // Fetch the price
    this.fetchPrice()
  }

  changeCropperRatio() {
    let width = this.printWidthTarget.value
    let height = this.printHeightTarget.value
    cropper.setAspectRatio(width / height);
    // cropper.options({
    //   autoCropArea: 1
    // })
  }

  cropperZoomIn() {
    cropper.zoom(0.03);
  }

  cropperZoomOut() {
    cropper.zoom(-0.03);
  }

  cropperZoomFit() {
    cropper.zoomTo(0);
  }

  cropperRotate() {
    let width = this.printWidthTarget.value
    let height = this.printHeightTarget.value
    this.printWidthTarget.value = height
    this.printHeightTarget.value = width
    this.updateSize()
  }

  updateSize() {
    this.changeCropperRatio()
    this.fetchPrice()
    this.updateSelectableFrames()
  }

  setFrameSelection() {
    let width = this.printWidthTarget.value
    let height = this.printHeightTarget.value
  }

  updateSelectableFrames() {
    let productType = this.productTypeTarget.value
    if(productType == 'photo') {
      let selectElement = this.framingTarget;
      let width = this.printWidthTarget.value
      let height = this.printHeightTarget.value

      // Clear all existing options
      selectElement.innerHTML = '';
      let option = document.createElement('option');
      option.text = `No Frame`;
      option.value = `none`;
      selectElement.add(option);

      // Check each frame size
      for(let i = 0; i < frameSizes.length; i++) {
        const frame = frameSizes[i];

        // Check if the print fits as is or when rotated
        if((width <= frame.width && height <= frame.height) ||
            (height <= frame.width && width <= frame.height)) {
          // Add the frame size to the select options
          let option = document.createElement('option');
          option.text = `${frame.width}x${frame.height}`;
          option.value = `${frame.width}x${frame.height}`;
          selectElement.add(option);
        }
      }
    }
  }

  async fetchPrice(freeLaminate) {
    let finalPrice = 0
    let width = this.printWidthTarget.value
    let height = this.printHeightTarget.value
    let productType = this.productTypeTarget.value

    console.log("##### productType: " + productType)

    let frameOption = productType == 'photo' ? this.framingTarget.value : 'none'
    let paper = this.paperTarget.value

    console.log("##### paper: " + paper)

    if (productType == 'photo') {
      const response = await fetch(`/prices?width=${width}&height=${height}&product=${paper}&frame=${frameOption}`)
      const data = await response.json()

      let laminationStatus = this.laminationCheckboxTarget.checked

      console.log("##### laminationStatus: " + laminationStatus)

      if (paper == 1 || paper == 8) {
        if(laminationStatus){
          console.log("##### laminating")
          if(freeLaminate) {
            finalPrice = Number(data.amount)
          } else {
            finalPrice = Number(data.amount) + 12
          }
        } else {
          finalPrice = Number(data.amount)
        }
      } else {
        console.log("##### NOT laminating")
        this.laminationCheckboxTarget.checked = false
        finalPrice = Number(data.amount)
      }
    } else if (productType == 'mounted') {
      // let mount = this.mountMediaTarget.value
      //
      // if (mount == 'foamcore') {
      //   this.productIdTarget.value = 3
      //   document.getElementById('mounted_info_foamboard').classList.add('d-none')
      //   document.getElementById('mounted_info_aluminum').classList.add('d-none')
      //   document.getElementById('mounted_info_coroplast').classList.add('d-none')
      //   document.getElementById('mounted_info_foamboard').classList.remove('d-none')
      // } else if (mount == 'aluminum') {
      //   this.productIdTarget.value = 5
      //   document.getElementById('mounted_info_foamboard').classList.add('d-none')
      //   document.getElementById('mounted_info_aluminum').classList.add('d-none')
      //   document.getElementById('mounted_info_coroplast').classList.add('d-none')
      //   document.getElementById('mounted_info_aluminum').classList.remove('d-none')
      // } else if (mount == 'coroplast') {
      //   this.productIdTarget.value = 7
      //   document.getElementById('mounted_info_foamboard').classList.add('d-none')
      //   document.getElementById('mounted_info_aluminum').classList.add('d-none')
      //   document.getElementById('mounted_info_coroplast').classList.add('d-none')
      //   document.getElementById('mounted_info_coroplast').classList.remove('d-none')
      // }

      const response = await fetch(`/prices?width=${width}&height=${height}&product=${paper}&frame=${frameOption}`)
      const data = await response.json()

      finalPrice = Number(data.amount)
    } else if (productType == 'banner') {
      const response = await fetch(`/prices?width=${width}&height=${height}&product=${paper}&frame=${frameOption}`)
      const data = await response.json()

      finalPrice = Number(data.amount)
    }

    this.subtotalPriceTarget.innerHTML = '$' + Number(finalPrice).toFixed(2)
    this.regularPriceTarget.innerHTML = '$' + (Number(finalPrice) * 1.40).toFixed(2)
  }

  updateLaminationPrice({ params: { freelaminate } }) {
    let laminationStatus = this.laminationCheckboxTarget.checked
    console.log("##### laminationStatus: " + laminationStatus + ", freelaminate: " + freelaminate)
    if(laminationStatus){
      this.tagTarget.value = 'laminated'
    } else {
      this.tagTarget.value = ''
    }
    this.fetchPrice(freelaminate)
  }

}