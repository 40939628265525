import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
      "deliveryPriceDisplay",
      "totalPriceDisplay",
      "deliveryOptions",
      "deliveryOption",
      "submitButton",
      "orderForm",
      "cardNumber"
  ]

  connect() {
  }

    async updateDelivery({ params: { subtotal, delivery } }) {
        this.deliveryOptionTarget.value = delivery

        const response = await fetch(`/delivery_options/${delivery}`)
        const data = await response.json()

        this.deliveryPriceDisplayTarget.innerHTML = '$' + Number(data.amount).toFixed(2)
        this.totalPriceDisplayTarget.innerHTML = '$' + (Number(data.amount) + subtotal).toFixed(2)

        this.deliveryOptionsTarget.querySelectorAll('div').forEach((option) => {
            option.classList.remove('selected_delivery')
        })

        document.getElementById(`delivery_option_${delivery}`).classList.add('selected_delivery')
    }

}