import { application } from "./application"

import DropzoneController from "./dropzone_controller"
import ProductController from "./product_controller"
import OrderController from "./order_controller"
import CheckoutController from "./checkout_controller"

application.register("dropzone", DropzoneController)
application.register("product", ProductController)
application.register("order", OrderController)
application.register("checkout", CheckoutController)
